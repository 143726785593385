import React, { Component } from 'react'
import axios from 'axios'
import {__DEV__, APIURL, PORT} from '../../config/config'
import NumberFormat from 'react-number-format';
import {Modal, ModalBody} from "reactstrap";
import {checkFormControls, checkGroupPermission, getFormControlName} from "../../services/grouppermission";
import DatePicker from "react-datepicker";
// import DatePicker from "react-datepicker";
import {format} from "date-fns";
import BDatePicker from '../BDatePicker'
import { addDataLog } from '../../services/dataLogService'

// const port = Number(window.env.PORT);

class CarSellExpensesEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      car_sell_type_id: String(this.props.car_sell.car_sell_type_id),
      car_sell_id: this.props.car_sell.car_sell_id,
      customer_name : this.props.car_sell.customer_name,
      // รายจ่ายเพิ่ม
      car_external_commission: this.props.car_sell.car_external_commission, // ค่านายหน้า ภายนอก
      sales_commission: this.props.car_sell.sales_commission, // ค่าคอมมิชชั่นฝ่ายขาย
      additional_commission: this.props.car_sell.additional_commission, // ค่าคอมมิชชั่นเพิ่มเติม
      advertising_cost: this.props.car_sell.advertising_cost, // ค่าโฆษณา
      delivery_fee: this.props.car_sell.delivery_fee, // ค่าบริการส่งรถ
      promotion_fee: this.props.car_sell.promotion_fee, // ค่าโปรโมชั่น
      finance_fee: this.props.car_sell.finance_fee, // ค่าธรรมเนียมไฟแนนท์
      transfer_fee: this.props.car_sell.transfer_fee, // ค่าโอนรถ
      cost_phra: this.props.car_sell.cost_phra, // พระ(เงินกองเงินที่บริษัทหักออกรายคัน )
      cost_vat_and_property_tax: this.props.car_sell.cost_vat_and_property_tax, // ภาษีมูลค่าเพิ่ม + ภาษีโรงเรือน
      cost_after_sales_service: this.props.car_sell.cost_after_sales_service, // บริการหลังการขาย
      //
      finance_pa: this.props.car_sell.finance_pa,
      finance_car_insurance: this.props.car_sell.finance_car_insurance,
      finance_ew: this.props.car_sell.finance_ew,
      finance_pay_advance: this.props.car_sell.finance_pay_advance,
      finance_health_insurance: this.props.car_sell.finance_health_insurance,
      finance_document_fee: this.props.car_sell.finance_document_fee,
      finance_transfer_fee: this.props.car_sell.finance_transfer_fee,
      finance_stamp_fee: this.props.car_sell.finance_stamp_fee,
      // รายรับ
      vat_sell: this.props.car_sell.vat_sell, // vat ขาย รายรับ
      finance_commission: this.props.car_sell.finance_commission,  // ค่าคอมมิชชั่นไฟแนนท์ รายรับ
      vat_commission: this.props.car_sell.vat_commission,  // vat คอมมิชชั่น รายรับ
      tax_invoice_amount: this.props.car_sell.tax_invoice_amount,
      tax_invoice_vat: this.props.car_sell.tax_invoice_vat,
      tax_commission_amount : this.props.car_sell.tax_commission_amount,
      tax_commission_vat : this.props.car_sell.tax_commission_vat,
      // at ประมาณการค่าโอน กรณี car_status_id = 23
      car_status_id : this.props.car_sell.car_status_id,
      transfer_fee_estimate : this.props.car_sell.transfer_fee_estimate,
      is_no_commission : this.props.car_sell.is_no_commission,
      finance_payment_date : this.props.car_sell.finance_payment_date !== null && this.props.car_sell.finance_payment_date !== '0000-00-00' ? new Date(this.props.car_sell.finance_payment_date) : null,
    };

    this.handleOnSubmitCarSellPriceEdit = this.handleOnSubmitCarSellPriceEdit.bind(this);
    this.renderInputEdit = this.renderInputEdit.bind(this);
    this.handleFinancePaymentDate = this.handleFinancePaymentDate.bind(this);
    this.handleAddDataLog = this.handleAddDataLog.bind(this);
  }

 /* componentDidMount() {

    let car_status_id = this.state.car_status_id

    if (__DEV__) {
      console.log('I am in debug');
      console.log('real car_status_id=', car_status_id, typeof car_status_id)
      car_status_id = 23;
      console.log('mock car_status_id=', car_status_id, typeof car_status_id)
      this.setState({
        car_status_id: car_status_id
      })
    }

  }*/

  handleOnSubmitCarSellPriceEdit(event) {
    event.preventDefault()

    let car_sell_id = this.state.car_sell_id
    // รายรับ
    // let vat_sell = event.target.vat_sell.value
    // let finance_commission = event.target.finance_commission.value
    // let vat_commission = event.target.vat_commission.value
    // ค่าใช้จ่ายการขาย
    let car_external_commission = event.target.car_external_commission.value
    let sales_commission = event.target.sales_commission.value
    let additional_commission = event.target.additional_commission.value
    let advertising_cost = event.target.advertising_cost.value
    let delivery_fee = event.target.delivery_fee.value
    let promotion_fee = event.target.promotion_fee.value
    let finance_fee = event.target.finance_fee.value
    let transfer_fee = event.target.transfer_fee.value
    // atcarsale
    let cost_phra = this.state.cost_phra
    let cost_vat_and_property_tax = this.state.cost_vat_and_property_tax
    let cost_after_sales_service = this.state.cost_after_sales_service

    // ค่าใช้จ่ายจากไฟแนนซ์
    let finance_pa = this.state.finance_pa
    let finance_car_insurance = this.state.finance_car_insurance
    let finance_ew = this.state.finance_ew
    let finance_pay_advance = this.state.finance_pay_advance
    let finance_health_insurance = this.state.finance_health_insurance
    let finance_document_fee = this.state.finance_document_fee
    let finance_transfer_fee = this.state.finance_transfer_fee
    let finance_stamp_fee = this.state.finance_stamp_fee
    //
    let tax_invoice_amount = this.state.tax_invoice_amount
    let tax_invoice_vat = this.state.tax_invoice_vat
    let vat_sell = this.state.vat_sell
    let finance_commission = this.state.finance_commission
    let vat_commission = this.state.vat_commission

    let tax_commission_amount = this.state.tax_commission_amount
    let tax_commission_vat = this.state.tax_commission_vat
    let transfer_fee_estimate = this.state.transfer_fee_estimate
    let is_no_commission = this.state.is_no_commission
    let finance_payment_date = this.state.finance_payment_date

    // console.log('finance_payment_date=', finance_payment_date)
    // if (this.state.finance_payment_date !== null) {
    //   finance_payment_date = format(this.state.finance_payment_date, 'yyyy-MM-dd')
    // }

    // vat_sell: vat_sell,
    // finance_commission: finance_commission,
    // vat_commission: vat_commission,

    let dataNew = {
      car_sell_id: car_sell_id,
      car_external_commission: car_external_commission,
      sales_commission: sales_commission,
      additional_commission: additional_commission,
      advertising_cost: advertising_cost,
      delivery_fee: delivery_fee,
      promotion_fee: promotion_fee,
      finance_fee: finance_fee,
      transfer_fee: transfer_fee,
      cost_phra: cost_phra,
      cost_vat_and_property_tax: cost_vat_and_property_tax,
      cost_after_sales_service: cost_after_sales_service,
      finance_pa: finance_pa,
      finance_car_insurance: finance_car_insurance,
      finance_ew: finance_ew,
      finance_pay_advance: finance_pay_advance,
      finance_health_insurance: finance_health_insurance,
      finance_document_fee: finance_document_fee,
      finance_transfer_fee: finance_transfer_fee,
      finance_stamp_fee: finance_stamp_fee,
      //
      tax_invoice_amount: tax_invoice_amount,
      tax_invoice_vat: tax_invoice_vat,
      vat_sell: vat_sell,
      finance_commission: finance_commission,
      vat_commission: vat_commission,
      //
      tax_commission_amount : tax_commission_amount,
      tax_commission_vat : tax_commission_vat,
      transfer_fee_estimate: transfer_fee_estimate,
      is_no_commission: is_no_commission,
      finance_payment_date: finance_payment_date
    }


    let username = this.props.username
    let car_sell_old = this.props.car_sell
    let data_log = {
      table_name: 'car_sell',
      ref_id: car_sell_id,
      menu_id: 802,
      form_name: 'แก้ไข ค่าใช้จ่าย/รายรับ',
      user_created: username
    }
    // this.handleAddDataLog(dataNew, car_sell_old, data_log)
    // return


    axios.put(APIURL + '/car_sell/expense_edit/'+car_sell_id, dataNew)
      .then(response => {
        if(response.status === 200) {
          this.handleAddDataLog(dataNew, car_sell_old, data_log)
          this.props.onToggle()
          this.props.onLoadCarSellById()
        }
      })
      .catch(error => {
        console.log("พบข้อผิดพลาด : ", error)
      })
  }

  handleAddDataLog(new_data, old_data,data_log) {
    let form_controls = this.props.form_controls
    let data_log_details = []
    let keys = Object.keys(new_data)

    for(let i =0 ; i < keys.length ; i++) {
      let key = keys[i]
      let new_value = new_data[String(key)];
      let old_value = old_data[String(key)];

      if (old_value === "0000-00-00" && new_value === null) {
        continue
      }

      // let form_control_filter = form_controls.filter(fc=> fc.field_name === key)
      // let label_name = form_control_filter.length === 0 ? '-' : form_control_filter[0].label_name

      if (!(new_value == old_value)) {
        let form_control_filter = form_controls.filter(fc=> fc.field_name === key)
        let label_name = form_control_filter.length === 0 ? '-' : form_control_filter[0].label_name
        let detail = {
          field_name: key,
          label_name: label_name,
          old_value: old_value,
          new_value: new_value
        }
        data_log_details.push(detail)
      }
    }

    if (data_log_details.length !== 0) {
      let data_log_new = { ...data_log, data_log_details: data_log_details }
      addDataLog(data_log_new)
    }
  }

  renderInputEdit(name, lable, form_controls){
    let value = this.state[name];

    let fix2 = parseFloat(String(value)).toFixed(2)
    let colCss = "form-group col-sm-2"
    let is_commission_field = false
    let is_commission_vat_field = false
    let is_no_commission = null
    let is_disabled = false

    if(name === 'tax_commission_amount' || name === 'finance_commission'){
       is_commission_field = true
       colCss = "form-group col-sm-3"
       is_no_commission = this.state.is_no_commission
       is_disabled = is_no_commission === 1
    }

    if(name === 'tax_commission_vat' || name === 'vat_commission'){
      is_commission_vat_field = true
      is_no_commission = this.state.is_no_commission
      is_disabled = is_no_commission === 1
    }

    return <div
        className={checkFormControls(name, form_controls)? colCss : "hidden"}
    >
      <label htmlFor={name}  >{lable}</label>

      {
        is_commission_field === true ? <>
          &nbsp;&nbsp; | &nbsp;&nbsp;
          <input type="checkbox"
                 name="is_no_commission"
                 value={this.state.is_no_commission}
                 checked={this.state.is_no_commission === 1}
                 onChange={(e) => {
                   let val = Number(e.target.value) === 0 ? 1 : 0

                   if(val === 1){
                     // 0 =  ไม่ระบุ / มี com , 1 = ไม่มี com
                     this.setState({
                       is_no_commission: val,
                       tax_commission_amount: 0,
                       tax_commission_vat: 0,
                       finance_commission:0,
                       vat_commission: 0
                     })
                   } else {
                     this.setState({
                       is_no_commission: val
                     })
                   }

                 }}
          />
          &nbsp;
          <span style={{fontSize: 14}} >ไม่มีคอมฯ </span>
        </> : null
      }

      <NumberFormat
          className="form-control"
          thousandSeparator={true}
          value={fix2}
          prefix={'฿'}
          min={0}
          max={1000000000}
          step={1000}
          size={10}
          allowNegative={false}
          disabled={is_disabled}
          onValueChange={(values) => {
            const {value} = values
            this.setState({
              [name]: value
            })
          }}
      />
    </div>
  }

  handleFinancePaymentDate (date) {
    this.setState({
      finance_payment_date: date
    })
  }

  render(){
    let width = 981;
    let form_controls = this.props.form_controls
    let grouppermissions = this.props.grouppermissions
    // let car_sell = this.props.car_sell
    let car_sell_type_id = this.state.car_sell_type_id

    let tax_invoice_amount_label = car_sell_type_id === '1' ? 'ค่าตัวรถ' : 'ยอดจัด'
    let tax_invoice_vat_label = car_sell_type_id === '1' ? 'VAT ของตัวรถ' : 'VAT ของยอดจัด'
    // style={styles.bottomLineAndMargin}

    let tax_commission_amount_name = this.state.tax_commission_amount !==0 ?  'tax_commission_amount' : 'finance_commission'
    let tax_commission_vat_name = this.state.tax_commission_vat !==0 ? 'tax_commission_vat' : 'vat_commission'

    // let finance_payment_date = this.state.finance_payment_date

    return (
        <Modal isOpen={this.props.isOpen} toggle={this.props.onToggle}
               className={'modal-lg ' + this.props.className}
               style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}>
          <ModalBody>
            <form action="" method="post" onSubmit={this.handleOnSubmitCarSellPriceEdit}>
        <div className="row" >
          <div className="col-sm-12">
            <div className="card card-accent-warning">
              <div className="card-header card-sparepart">
                <strong className="text-title">ค่าใช้จ่ายการขาย</strong>
              </div>

              <div className="card-block">

                <div className="row"
                     style={checkGroupPermission(802, grouppermissions).modified ? styles.bottomLineAndMargin : {display: 'none'}}
                >

                  <div className="form-inline col-sm-12 mb-1">
                    <u><h6><strong>ค่าใช้จ่ายการขาย (บันทึกกรณีจ่ายแล้ว)</strong></h6></u>
                    <span className="ml-1 text-danger"><strong>**ทางเต็นท์รับผิดชอบ ไม่ได้เก็บกับลูกค้าเพิ่ม**</strong></span>
                  </div>

                  <div
                      className={checkFormControls('car_external_commission', form_controls)? "form-group col-sm-2" : "hidden"}
                  >
                    <label htmlFor="car_external_commission">
                      ค่านายหน้า
                    </label>
                    <input type="text"
                           className="form-control hidden"
                           id="car_external_commission"
                           name="car_external_commission"
                           placeholder=""
                           ref="car_external_commission"
                           value={this.state.car_external_commission}
                           onChange={()=>{}}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.car_external_commission}
                      onValueChange={(values) => {
                        const {value} = values
                        this.setState({
                          car_external_commission: value
                        })
                      }}
                    />
                  </div>

                  <div
                      className={checkFormControls('sales_commission', form_controls)? "form-group col-sm-2" : "hidden"}
                  >
                    <label htmlFor="sales_commission">ค่าคอมมิชชั่นฝ่ายขาย</label>
                    <input type="text"
                           className="form-control hidden"
                           id="sales_commission"
                           name="sales_commission"
                           placeholder=""
                           ref="sales_commission"
                           value={this.state.sales_commission}
                           onChange={()=>{}}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.sales_commission}
                      onValueChange={(values) => {
                        const {value} = values
                        this.setState({
                          sales_commission: value
                        })
                      }}
                    />
                  </div>

                  <div
                      className={checkFormControls('additional_commission', form_controls)? "form-group col-sm-2" : "hidden"}
                  >
                    <label htmlFor="additional_commission">
                      {getFormControlName('additional_commission', form_controls)}
                    </label>
                    <input type="text"
                           className="form-control hidden"
                           id="additional_commission"
                           name="additional_commission"
                           placeholder=""
                           ref="additional_commission"
                           value={this.state.additional_commission}
                           onChange={()=>{}}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.additional_commission}
                      onValueChange={(values) => {
                        const {value} = values
                        this.setState({
                          additional_commission: value
                        })
                      }}
                    />
                  </div>

                  <div
                      className={checkFormControls('advertising_cost', form_controls)? "form-group col-sm-2" : "hidden"}
                  >
                    <label htmlFor="advertising_cost">ค่าโฆษณา</label>
                    <input type="text"
                           className="form-control hidden"
                           id="advertising_cost"
                           name="advertising_cost"
                           placeholder=""
                           ref="advertising_cost"
                           value={this.state.advertising_cost}
                           onChange={()=>{}}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.advertising_cost}
                      onValueChange={(values) => {
                        const {value} = values
                        this.setState({
                          advertising_cost: value
                        })
                      }}
                    />
                  </div>

                  <div
                      className={checkFormControls('delivery_fee', form_controls)? "form-group col-sm-2" : "hidden"}
                  >
                    <label htmlFor="delivery_fee">ค่าบริการส่งรถ</label>
                    <input type="text"
                           className="form-control hidden"
                           id="delivery_fee"
                           name="delivery_fee"
                           placeholder=""
                           ref="delivery_fee"
                           value={this.state.delivery_fee}
                           onChange={()=>{}}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.delivery_fee}
                      onValueChange={(values) => {
                        const {value} = values
                        this.setState({
                          delivery_fee: value
                        })
                      }}
                    />
                  </div>

                  <div
                      className={checkFormControls('promotion_fee', form_controls)? "form-group col-sm-2" : "hidden"}
                  >
                    <label htmlFor="promotion_fee">ค่าโปรโมชั่น</label>
                    <input type="text"
                           className="form-control hidden"
                           id="promotion_fee"
                           name="promotion_fee"
                           placeholder=""
                           ref="promotion_fee"
                           value={this.state.promotion_fee}
                           onChange={()=>{}}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.promotion_fee}
                      onValueChange={(values) => {
                        const {value} = values
                        this.setState({
                          promotion_fee: value
                        })
                      }}
                    />
                  </div>


                  <div
                       className={checkFormControls('finance_fee', form_controls)? "form-group col-sm-2" : "hidden"}
                  >
                    <label htmlFor="finance_fee">ค่าธรรมเนียมไฟแนนท์</label>
                    <input type="text"
                           className="form-control hidden"
                           id="finance_fee"
                           name="finance_fee"
                           placeholder=""
                           ref="finance_fee"
                           value={this.state.finance_fee}
                           onChange={()=>{}}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.finance_fee}
                      onValueChange={(values) => {
                        const {value} = values
                        this.setState({
                          finance_fee: value
                        })
                      }}
                    />
                  </div>

                  <div
                       className={checkFormControls('transfer_fee', form_controls)? "form-group col-sm-2" : "hidden"}
                  >
                    <label htmlFor="transfer_fee">ค่าโอนรถ</label>
                    <input type="text"
                           className="form-control hidden"
                           id="transfer_fee"
                           name="transfer_fee"
                           placeholder=""
                           ref="transfer_fee"
                           value={this.state.transfer_fee}
                           onChange={()=>{}}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.transfer_fee}
                      onValueChange={(values) => {
                        const {value} = values
                        this.setState({
                          transfer_fee: value
                        })
                      }}
                    />
                  </div>

                      <div
                           className={checkFormControls('cost_phra', form_controls)? "form-group col-sm-2" : "hidden"}
                      >
                        <label htmlFor="cost_phra">พระ</label>
                        <input type="text"
                               className="form-control hidden"
                               id="cost_phra"
                               name="cost_phra"
                               placeholder=""
                               value={this.state.cost_phra}
                               onChange={()=>{}}
                        />
                        <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          prefix={'฿'}
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.cost_phra}
                          onValueChange={(values) => {
                            const {value} = values
                            this.setState({
                              cost_phra: value
                            })
                          }}
                        />
                      </div>

                      <div
                          className={checkFormControls('cost_after_sales_service', form_controls)? "form-group col-sm-2" : "hidden"}
                      >
                        <label htmlFor="cost_after_sales_service">บริการหลังการขาย</label>
                        <input type="text"
                               className="form-control hidden"
                               id="cost_after_sales_service"
                               name="cost_after_sales_service"
                               placeholder=""
                               value={this.state.cost_after_sales_service}
                               onChange={()=>{}}
                        />
                        <NumberFormat
                            className="form-control"
                            thousandSeparator={true}
                            prefix={'฿'}
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.cost_after_sales_service}
                            onValueChange={(values) => {
                              const {value} = values
                              this.setState({
                                cost_after_sales_service: value
                              })
                            }}
                        />
                      </div>

                      <div
                           className={checkFormControls('cost_vat_and_property_tax', form_controls)? "form-group col-sm-2" : "hidden"}
                      >
                        <label htmlFor="cost_vat_and_property_tax" style={{fontSize: 12}} >ภาษีมูลค่าเพิ่ม + ภาษีโรงเรือน</label>
                        <input type="text"
                               className="form-control hidden"
                               id="cost_vat_and_property_tax"
                               name="cost_vat_and_property_tax"
                               placeholder=""
                               value={this.state.cost_vat_and_property_tax}
                               onChange={()=>{}}
                        />
                        <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          prefix={'฿'}
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.cost_vat_and_property_tax}
                          onValueChange={(values) => {
                            const {value} = values
                            this.setState({
                              cost_vat_and_property_tax: value
                            })
                          }}
                        />
                      </div>

                  {
                    this.state.car_status_id === 23 &&
                    <div
                        className={checkFormControls('transfer_fee_estimate', form_controls)? "form-group col-sm-2" : "hidden"}
                    >
                      <label htmlFor="transfer_fee_estimate" style={{fontSize: 12}} > ประมาณการค่าโอน </label>
                      <input type="text"
                             className="form-control hidden"
                             id="transfer_fee_estimate"
                             name="transfer_fee_estimate"
                             placeholder=""
                             value={this.state.transfer_fee_estimate}
                             onChange={()=>{}}
                      />
                      <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          prefix={'฿'}
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.transfer_fee_estimate}
                          onValueChange={(values) => {
                            const {value} = values
                            this.setState({
                              transfer_fee_estimate: value
                            })
                          }}
                      />
                    </div>
                  }



                </div>

                <div className="row"
                     style={checkGroupPermission(803, grouppermissions).modified ? styles.bottomLineAndMargin : {display: 'none'}}
                >
                  <div className="form-inline col-sm-12">
                    <u><h6>ค่าใช้จ่ายจากไฟแนนซ์ (บันทึกกรณีจ่ายแล้ว)</h6></u>
                    <span className="ml-1 text-danger">**ค่าใช้จ่ายที่จ่ายให้กับไฟแนนซ์**</span>
                  </div>

                  {this.renderInputEdit('finance_pa', 'PA', form_controls)}
                  {this.renderInputEdit('finance_car_insurance', 'ประกันรถยนต์', form_controls)}
                  {this.renderInputEdit('finance_ew', 'EW ประกันเครื่องยนต์', form_controls)}
                  {this.renderInputEdit('finance_pay_advance', 'ค่างวดล่วงหน้า', form_controls)}
                  {this.renderInputEdit('finance_health_insurance', 'ประกันสุขภาพ', form_controls)}
                  {this.renderInputEdit('finance_document_fee', 'ค่าเอกสาร', form_controls)}
                  {this.renderInputEdit('finance_transfer_fee', 'ค่าโอนจากไฟแนนซ์', form_controls)}
                  {this.renderInputEdit('finance_stamp_fee', 'ค่าอากร', form_controls)}

                </div>

                <div className="row"
                     style={checkGroupPermission(801, grouppermissions).modified ? styles.bottomLineAndMargin : {display: 'none'}}
                >
                  <div className="form-inline col-sm-12">
                    <u><h6>รายรับการขาย (บันทึกกรณีรับจริง)</h6></u>
                    <span className="ml-1 text-danger">**ยอดจัด/ค่าคอมมิชชั่น ดึงค่าจากการออกใบกำกับ**</span>
                  </div>

                  {this.renderInputEdit('tax_invoice_amount', tax_invoice_amount_label, form_controls)}
                  {this.renderInputEdit('tax_invoice_vat', tax_invoice_vat_label, form_controls)}

                  {this.renderInputEdit('vat_sell', 'VAT ยอดจัดได้คืน', form_controls)}
                  {this.renderInputEdit(tax_commission_amount_name, 'ค่าคอมมิชชั่นไฟแนนท์', form_controls)}
                  {this.renderInputEdit(tax_commission_vat_name, 'VAT คอมมิชชั่น', form_controls)}

                  <div
                      className={checkFormControls('finance_payment_date', form_controls) && PORT === 9043 ? "form-group col-sm-3" : "hidden"}
                  >
                    {/*<label htmlFor="finance_payment_date">วันที่รับเงิน(ไฟแนนซ์) </label> <br/>
                    <DatePicker
                        selected={this.state.finance_payment_date}
                        onChange={this.handleFinancePaymentDate}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        id="finance_payment_date"
                        name="finance_payment_date"
                        placeholder=""
                    />
                     */}
                    <BDatePicker
                      isDateFill={0}
                      name={'finance_payment_date'}
                      value={this.state.finance_payment_date}
                      label={'วันที่รับเงิน(ไฟแนนซ์)'}
                      onUpdateDate={(buddhist_date, christ_date) => {
                        this.setState({
                          finance_payment_date: christ_date
                        })
                      }}
                    />

                  </div>

                </div>

              </div>

              <div className="card-footer text-right">
                <button type="button"
                        className="btn btn-sm btn-danger mr-2"
                        onClick={this.props.onToggle}
                >
                  <i className="fa fa-refresh"/> ยกเลิก
                </button>

                <button type="submit"
                        className="btn btn-sm btn-warning">
                  <i className="fa fa-save"/> แก้ไขข้อมูล
                </button>
              </div>

            </div>
          </div>
        </div>
      </form>
          </ModalBody>
        </Modal>
    )
  }
}

const styles = {
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
  },
  bottomLineAndMargin: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
    paddingTop: 8
  }
}

export default CarSellExpensesEdit;
